export default {
    query (data) {
        if (!data) {
            return ''
        }
        var str = ''
        var e = '&'
        const val = Object.keys(data).length
        Object.keys(data).forEach((key, index) => {
            if (data[key]) {
                str += `${e}${key}=${data[key]}${index < val - 1 ? '' : ''}`
            } else {
                str += ''
            }
        })
        return str
    }
}
